import axios from 'axios'
import qs from 'qs'

export const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000, // request timeout,
    headers: {
        // "Content-Type": "application/json"
    },
    transformRequest: [function (data, headers) {
        // 上传格式则不进行序列化 序列化会去掉分隔符
        if (headers['Content-Type'] === 'multipart/form-data') {
            return data
        }
        // 对 data 进行任意转换处理
        return qs.stringify(data, { arrayFormat: 'brackets' })
    }],
})

service.interceptors.request.use(
    function (config) {
        // 需要设置 token
        config.headers['Authorization'] = "you token" // 让每个请求携带自定义token 请根据实际情况自行修改
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    function (config) {
        const code = config.data.code || config.data.status;
        const msg = config.data.message || config.data.msg;

        if (config.status == 200) {
            if (code == 200) {
                return config
            }
            else {
                if (msg) {
                    console.log(msg);
                }
            }
        } else {
            console.log(config.data.message || config.data.msg);
        }
        return config
    },
    function (error) {
        if (error.toString().indexOf('Error: timeout') !== -1) {
            console.log("网络请求超时");
        } else if (error.toString().indexOf('Error: Network Error') !== -1) {
            console.log("网络请求错误");
        } else {
            console.log(error)
            // console.log(error.response.data.message == null ? '服务器异常' : error.response.data.message);
        }
        return Promise.reject(error)
    }
)


export default service
/**
 * get方法，对应get请求GET
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function GET(url, params) {
    return new Promise((resolve, reject) => {
        service
            .get(url, {
                params: params,
                paramsSerializer: function (params) {
                    return QS.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                }
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err.data)
            })
    })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function POST(url, params) {
    return new Promise((resolve, reject) => {
        service.post(url).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}